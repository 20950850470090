var sakusunApp = angular.module('sakusunApp', ['ui.bootstrap',
    'uiGmapgoogle-maps',
    'ngTouch',
    'ngAnimate',
    'perfect_scrollbar',
    'sakControllers'
]);/**
 * Created by Marklar14 on 13. 3. 2015.
 */
sakusunApp.run(['$rootScope','$http','$cacheFactory','$window', function($rootScope,$http,$cacheFactory,$window) {
    $rootScope.folder = "partials";
    var headerHeight =  ($($window).width() < 1000) ? 0 : $("header").height();
    var height = (headerHeight > 195) ? headerHeight : 195;
    var h = window.innerHeight;
    var w = $window.innerWidth;
    var introH = h - ( height );
    var introHeight = introH + 'px';
    $rootScope.nabidkaHeight = 0;
    $rootScope.introMarginTop = { top: headerHeight + 8 + 'px' };
    $rootScope.introMarginTop2 = { top: headerHeight + (headerHeight * 0.08) + 'px' };
    $rootScope.introStyle = { height:  introHeight };

    function scroll_if_anchor(href, fromTop) {

        href = typeof(href) == "string" ? href : $(this).attr("href");

        // If href missing, ignore
        if(!href) return;

        // You could easily calculate this dynamically if you prefer

        // If our Href points to a valid, non-empty anchor, and is on the same page (e.g. #foo)
        // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
        var $target = $(href);

        // Older browsers without pushState might flicker here, as they momentarily
        // jump to the wrong position (IE < 10)

        if($target.length) {
            $('html, body').animate({ scrollTop: $target.offset().top - fromTop}, 1500, "easeInOutExpo");
        }
    }

// When our page loads, check to see if it contains and anchor
    scroll_if_anchor(window.location.hash,headerHeight);

}]);

/**
 * Created by Marklar14 on 24. 3. 2015.
 */
