sakusunApp.directive("scroll", ['$window', function ($window) {
    return function(scope, element, attrs) {
        angular.element($window).bind("scroll", function() {
            var header = $(".ng-hidden-scroll");
            var classAdd = "display-none";
            if($(window).scrollTop() > 0) {
                header.addClass(classAdd);
            }
            else
            {
                if(header.hasClass(classAdd))
                {
                    header.removeClass(classAdd);
                }
            }
        });
    };

}]);

 sakusunApp.directive('a', function() {
    return {
        restrict: 'E',
        link: function(scope, elem, attrs) {
            if(elem.hasClass("show")){
                elem.on('click', function(e){
                    e.preventDefault();
                });
            }
        }
   };
});

sakusunApp.directive('scrollOnClick', ['$rootScope', function( $rootScope) {
    return {
        restrict: 'A',
        link: function($scope, $elm, $rootScope) {
            $elm.on('click', function(e) {
                var element = $($elm.attr('href'));
                var $height = ($(window).width() < 1024) ? 0 : $("header").height();
                if(angular.element(element).length)
                {
                    e.preventDefault();
                    $("html,body").animate({scrollTop: $(element).offset().top - $height}, 1500, "easeInOutExpo");
                }
            });
        }
    }
}]);

sakusunApp.directive('ngProduktDescription',['$compile', function($compile) {
   return {
       restrict: 'AE',
       link: function($scope, $elm) {
       var html = $elm.html();
       if($(".popis").length > 0)
       {
           var popis = $(html).filter(".popis").html();
           $elm.find(".popis").hide();
           $elm.after("<div class=\"col-md-12\">" + popis + "</div>");
       }

       }
   }
}]);

sakusunApp.directive('ngShowMessage', function() {
return {

    restrict: 'A',
    link: function($scope, $elm) {
     var $message = angular.element("<div class='infoMessage'> <strong>Připravujeme...</strong> <br /> Kontaktujte nás. </div>");
     $elm.on("mouseenter", function() {
         $elm.after($message);
     });
     $elm.on("mouseleave", function() {
      $message.remove();
     });
    }
}
});
