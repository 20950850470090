var sakControllers = angular.module('sakControllers', []);

sakControllers.controller('myCtrl', ['$scope','$rootScope', function($scope,$rootScope) {

    $scope.myInterval = 5000;

}]);


sakControllers.controller('formularCtrl', ['$scope','$rootScope','$http', function($scope,$rootScope,$http) {

    $scope.form = {};
    $scope.form.human = "Jsem člověk";
    $scope.showErrorMessage = false;

    $scope.submitForm = function(isValid) {

        // check to make sure the form is completely valid
        if (isValid) {
            $http({
                method : 'POST',
                url : '/backend/formular/odeslat',
                data : {name: $scope.form.name, email: $scope.form.email,
                    tel: $scope.form.tel, adresa: $scope.form.adresa, bydliste: $scope.form.bydliste,
                    pozadavek: $scope.form.pozadavek, system: $scope.form.system, human: $scope.form.human}// set the headers so angular passing info as form data (not request payload)
            })
                .success(function(data) {
                    if (!data.success) {
                        // if not successful, bind errors to error variables
                        $scope.errorMessage = data.message;
                        $scope.showErrorMessage = true;

                    } else {
                        // if successful, bind success message to message
                        $scope.message = data.message;
                        $scope.showMessage = true;
                    }
                });
        }

    };
}]);

sakControllers.controller('kontaktCtrl', ['$scope', function($scope) {

    $scope.map = { center: { latitude: 49.9771246, longitude: 14.350433199999998 }, zoom: 16 };
    $scope.markers = [{
        id: 0,
        coords: {
            latitude: 49.9771246,
            longitude: 14.350433199999998
        },
        data: 'SAK Radotín',
        options: {
            labelContent: '<div class=\"box blue\"><div class=\"row\"><div class=\"col-md-4 hidden-xs hidden-sm\"><img src=\"app/assets/images/sak.png\" alt=\"\" class=\"center-block img-responsive\"/></div><div class=\"col-md-8 col-sm-12 col-xs-12\"><h2>SAK Radotín s.r.o. <br /> Karlická 1407/39A <br /> 153 00 Praha 16</h2></div></div> <div class=\"row\"> <div class=\"col-md-12 col-sm-12 col-xs-12\">  <p> <strong>email:</strong> info@sakradotin.cz<br/> <strong>tel:</strong>	257 812 160<br/> <strong>fax:</strong>	257 911 177 </p></div></div></div>',
            labelAnechor: '26 0',
            labelClass: 'marker-labels',
            labelVisible: true
        }
    }];

    $scope.addMarkerClickFunction = function (markersArray) {
        angular.forEach(markersArray, function (value, key) {
            value.onClick = function () {
                $scope.onClick(value.data);
                $scope.MapOptions.markers.selected = value;
            };
        });
    };

    var rozmer = ($(window).width() < 1024) ? $(window).height() : $(window).height() - angular.element("header").height();
    angular.element(".angular-google-map-container").css('height', rozmer);
}]);


